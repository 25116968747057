.portfolio-notification{
    background-color: var(--primary-color) !important;
    color: var(--white) !important;
}
.porfolio-grid-holder {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}

.portfolio-grid {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
}


@media only screen and (max-width: 580px) {
    .porfolio-grid-holder  {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}