.navbar {
    background-color: var(--white);
    opacity: 1;
    background-image: radial-gradient(rgba(8, 44, 102, 0.08) 1px, #FFF 1px);
    background-size: 10px 10px;
}

.navbar-item {
    color: var(--black) !important;
    background-color: transparent !important;
}

.navbar-item:hover {
    color: var(--black) !important;
    background-color: transparent !important;
}

.navbar-link:hover {
    color: var(--black) !important;
    background-color: transparent !important;
}