/* Import Font Awesome */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css");
/* Import Animate CSS */
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

/* Fonts */

@font-face {
  font-family: ProductSans-Regular;
  src: url("./assets/fonts/ProductSans-Regular.ttf");
}

@font-face {
  font-family: ProductSans-Bold;
  src: url("./assets/fonts/ProductSans-Bold.ttf");
}

/* Global styles */

html{
  margin: 0 !important;
}

body {
  margin: 0;
  font-family: ProductSans-Regular, "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black) !important;
  background-color: var(--white);
  opacity: 1;
  background-image: radial-gradient(rgba(8, 44, 102, 0.08) 1px, #FFF 1px);
  background-size: 10px 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Color Palette */
:root {
  --primary-color: #082c66;
  --black: #000000;
  --white: #ffffff
}

/* Custom helpers */

.has-bold-text {
  font-family: ProductSans-Bold, "Helvetica Neue", sans-serif !important;
}

.is-primary-button {
  background-color: var(--primary-color) !important;
  color: var(--white) !important;
}
