.showcase-content{
    padding: 4rem;
    padding-top: 3rem !important;
    text-align: center !important;
}
.react-pdf__Document{
    display: flex;
    justify-content: center;
}
.text-download-btn{
    width: 80%;
}
.menu-list a.is-active{
    background-color: var(--primary-color) !important;
}