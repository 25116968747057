.contact-us-title{
    font-size: 6rem !important;
}
.contact-form input:focus, textarea:focus{
    border: 2px solid var(--primary-color) !important;
    box-shadow: none !important;
}

@media screen and (max-width: 431px) {
    .contact-us-title {
        font-size: 4.5rem !important;
    }
}