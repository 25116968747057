.serices-section {
    margin-left: 3rem;
    margin-right: 3rem;
    padding: 3rem;
    border-radius: 2rem;
    background-color: var(--primary-color);
}

.services-banner-title {
    font-size: 3.5rem !important;
    font-weight: normal;
    color: var(--white) !important;
}

.services-banner-title span {
    font-weight: bolder;
}

.services-icon {
    width: 3rem;
    margin-bottom: 1.5rem;
    filter: invert(100%);
}