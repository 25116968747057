/* Landing */
.home-landing-wrapper {
    min-height: 100vh !important;
}
.landing-section{
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding-left: 3rem;
    padding-right: 3rem;
}

/* Hover animations */

/* Icon Forward */
.hvr-icon-forward {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
  }
  .hvr-icon-forward .hvr-icon {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-icon-forward:hover .hvr-icon, .hvr-icon-forward:focus .hvr-icon, .hvr-icon-forward:active .hvr-icon {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
