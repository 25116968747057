.about-us {
    margin-bottom: 6rem !important;
}

.about-us-text {
    font-size: 3.5rem !important;
}

.about-image {
    width: 40rem;
    border-radius: 20px;
}

.about-us-services-grid {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
}

.service-card-icon {
    font-size: 25px;
}

.about-us-service-card {
    color: var(--primary-color) !important;
}

.about-us-service-card:hover {
    color: var(--white) !important;
    background-color: var(--primary-color) !important;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

.about-us-service-card:hover .about-us-service-card-title {
    color: var(--white) !important;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}