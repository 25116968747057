/* Home Hero */
.home-hero {
    min-height: 95vh !important;
}

.home-hero-columns {
    min-height: 100vh !important;
}

.home-hero-title {
    font-size: 5rem !important;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 3rem !important;
}

.home-hero-underlined {
    text-decoration: underline;
}

.home-hero-subtitle {
    font-size: 1.5rem !important;
}

.home-hero-img {
    width: 40rem;
}

.home-hero-work-button{
    margin-left: 1rem;
}

.project-counter-mobile{
    display: none;
}

@media screen and (max-width: 463px) {
    .home-hero-buttons-group button {
        margin-left: 0 !important;
        margin-bottom: 1rem;
    }

    .home-hero-title span {
        margin-left: 0.5rem !important;
    }
}

@media screen and (max-width: 394px) {
    .home-hero-title span {
        margin-left: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .home-hero-title {
        margin-top: 3rem;
        font-size: 5rem !important;
    }
}

@media screen and (max-width: 593px) {
    .home-hero-title {
        margin-top: 3rem;
        font-size: 2.5rem !important;
    }
}

@media screen and (max-width: 450px) {
    .home-hero-work-button {
        margin-left: 0 !important;
    }
}

@media screen and (max-width: 508px) {
    .project-counter-mobile {
        display: block !important;
    }
    .project-counter-plus{
        display: none !important;
    }
    .project-counter-desktop{
        display: none !important;
    }
}